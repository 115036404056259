import { toast } from "react-toastify";
import { ApiRequest } from "../../../utils/apiHelpers/apiRequest.ts";
import { asyncWrapper } from "../../../utils/apiHelpers/handleApiError.ts";
import { handleLoadings } from "../../../utils/apiHelpers/handleLoading.ts";
import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";

export const createPackageAsyncThunk = createAsyncThunk(
  "auth/createPackageAsyncThunk",
  asyncWrapper(async ({ data, action, navigate }, thunk) => {
    const response = await ApiRequest.createPackage(data);
    if (response.status == 201) {
      toast.success("Package created successfully!");
      action.resetForm();
      navigate("/company/packages");
    }
    return response.data;
  })
);
export const editPackageAsyncThunk = createAsyncThunk(
  "auth/editPackageAsyncThunk",
  asyncWrapper(async ({ data, id, navigate }, thunk) => {
    const response = await ApiRequest.editPackage({ data, id });
    if (response.status == 200) {
      toast.success("Package updated successfully!");
      navigate("/company/packages");
    }
    return response.data;
  })
);
export const getPackagesAsyncThunk = createAsyncThunk(
  "auth/getPackagesAsyncThunk",
  asyncWrapper(async (data, thunk) => {
    const response = await ApiRequest.getPackages(data);
    return response.data;
  })
);
export const getPackageAsyncThunk = createAsyncThunk(
  "auth/getPackageAsyncThunk",
  asyncWrapper(async (data, thunk) => {
    const response = await ApiRequest.getPackage(data);
    return response.data;
  })
);
export const getPublishPackageAsyncThunk = createAsyncThunk(
  "auth/getPublishPackageAsyncThunk",
  asyncWrapper(async (data, thunk) => {
    const response = await ApiRequest.getPublishPackage(data);
    return response.data;
  })
);

export const searchPackageAsyncThunk = createAsyncThunk(
  "auth/searchPackageAsyncThunk",
  asyncWrapper(async (data, thunk) => {
    const response = await ApiRequest.searchPackage(data);
    console.log("response", response)
    if (response.status == 200) {
      toast.success("Package delete successfully!");
      thunk.dispatch(getPackagesAsyncThunk(response.data));
    }
    return response;
  })
);
export const deletePackageAsyncThunk = createAsyncThunk(
  "auth/deletePackageAsyncThunk",
  asyncWrapper(async (id, thunk) => {
    const response = await ApiRequest.deletePackage(id);
    if (response.status == 200) {
      toast.success("Package delete successfully!");
      thunk.dispatch(getPackagesAsyncThunk());
    }
    return response.data;
  })
);

export const publishPackageAsyncThunk = createAsyncThunk(
  "auth/publishPackageAsyncThunk",
  asyncWrapper(async ({ data, id, navigate }, thunk) => {
    const response = await ApiRequest.publishPackage({ data, id });
    if (response.status == 200) {
      toast.success("Package updated successfully!");
      if (navigate) {
        navigate("/company/packages");
      }
    }
    return response.data;
  })
);

export const createEssayAsyncThunk = createAsyncThunk(
  "auth/createEssayAsyncThunk",
  asyncWrapper(async ({ data, pckg }, thunk) => {
    const response = await ApiRequest.createEssay(data);
    if (response.status == 201) {
      toast.success("Essay added successfully!");
      thunk.dispatch(getEssaysAsyncThunk(pckg));
    }
    return response.data;
  })
);

export const editEssayAsyncThunk = createAsyncThunk(
  "auth/editEssayAsyncThunk",
  asyncWrapper(async ({ data, id, pckg, fun }, thunk) => {
    const response = await ApiRequest.editEssay({ data, id });
    if (response.status == 200) {
      toast.success("Essay updated successfully!");
      thunk.dispatch(getEssaysAsyncThunk(pckg));
      fun();
    }
    return response.data;
  })
);

export const deleteEssayAsyncThunk = createAsyncThunk(
  "auth/deleteEssayAsyncThunk",
  asyncWrapper(async (id, thunk) => {
    const response = await ApiRequest.deleteEssay(id);
    if (response.status == 200) {
      toast.success("Essay deleted successfully!");
      thunk.dispatch(getEssaysAsyncThunk());
    }
    return response.data;
  })
);

export const getEssaysAsyncThunk = createAsyncThunk(
  "auth/getEssaysAsyncThunk",
  asyncWrapper(async (data, thunk) => {
    const response = await ApiRequest.getEssays(data);
    return response.data;
  })
);

export const addQuestionAsyncThunk = createAsyncThunk(
  "auth/addQuestionAsyncThunk",
  asyncWrapper(
    async (
      { data, action, setCorrectOptionId, addMore, navigate },
      thunk
    ) => {
      const response = await ApiRequest.addQuestion(data);
      if (response.status == 201) {
        toast.success("Question added successfully!");
        action.resetForm();
        setCorrectOptionId("");
        if (addMore) {
          navigate("/company/question-bank");
        }
      }
      return response.data;
    }
  )
);

export const addComprehensionAsyncThunk = createAsyncThunk(
  "auth/addComprehensionAsyncThunk",
  asyncWrapper(
    async ({ data, action, setCorrectOptionId, addMore, navigate }, thunk) => {
      const response = await ApiRequest.addComprehension(data);
      if (response.status == 201) {
        toast.success("Reading question added successfully!");
        action.resetForm();
        setCorrectOptionId([]);
        if (addMore) {
          navigate("/company/question-bank");
        }
      }
      return response.data;
    }
  )
);
export const addBulkQuestionsAsyncThunk = createAsyncThunk(
  "auth/addBulkQuestionsAsyncThunk",
  asyncWrapper(
    async ({ data, action, setCorrectOptionId, navigate }, thunk) => {
      const response = await ApiRequest.addBulkQuestions(data);
      if (response.status == 201) {
        toast.success("Question added successfully!");
        action.resetForm();
        setCorrectOptionId([]);
        navigate("/company/question-bank");
      }
      return response.data;
    }
  )
);

const initialState = {
  packages: {},
  package: {},
  essays: [],
  excelQuestion: {},
  wordQuestions: {},
  loadings: {
    createPackageAsyncThunk: false,
    getPackagesAsyncThunk: false,
    getPackageAsyncThunk: false,
    deletePackageAsyncThunk: false,
    searchPackageAsyncThunk: false,
    editPackageAsyncThunk: false,
    editEssayAsyncThunk: false,
    addQuestionAsyncThunk: false,
    deleteEssayAsyncThunk: false,
    addComprehensionAsyncThunk: false,
    addBulkQuestionsAsyncThunk: false,
    publishPackageAsyncThunk: false,
    getPublishPackageAsyncThunk: false,
  },
};

export const packageSlice = createSlice({
  name: "package",
  initialState,
  extraReducers: (builder) => {
    builder
      // packages
      .addCase(getPackagesAsyncThunk.fulfilled, (state, action) => {
        // console.log("actionaction-->", action.payload)
        state.packages = action.payload;
      })
      .addCase(getPackageAsyncThunk.fulfilled, (state, action) => {
        // console.log("action--->", action.payload);
        
        state.package = action.payload;
      })
      .addCase(getPublishPackageAsyncThunk.fulfilled, (state, action) => {
        state.package = action.payload;
      })
      .addCase(searchPackageAsyncThunk.fulfilled, (state, action) => {
        console.log("search--->", action.payload)
        state.packages = action.payload;
      })
      // .addCase(deletePackageAsyncThunk.fulfilled, (state, action) => {
      //   state.packages = action.payload;
      // })
      // essays
      .addCase(getEssaysAsyncThunk.fulfilled, (state, action) => {
        state.essays = action.payload;
      })
      // questions
      
      .addMatcher(
        isAnyOf(
          createPackageAsyncThunk,
          getPackagesAsyncThunk,
          getEssaysAsyncThunk,
          deletePackageAsyncThunk,
          searchPackageAsyncThunk,
          editPackageAsyncThunk,
          editEssayAsyncThunk,
          addQuestionAsyncThunk,
          deleteEssayAsyncThunk,
          addComprehensionAsyncThunk,
          addBulkQuestionsAsyncThunk,
          publishPackageAsyncThunk,
          getPublishPackageAsyncThunk
        ),
        handleLoadings
      );
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    addExcelQuestion(state, action) {
      state.excelQuestion = action.payload;
    },
    addWordQuestions(state, action) {
      state.wordQuestions = action.payload;
    },
  },
});

export default packageSlice.reducer;
export const { setUser, addExcelQuestion, addWordQuestions } =
  packageSlice.actions;
