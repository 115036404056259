import axios from "axios";
import { toast } from "react-toastify";

export default function handleApiError(error: any) {
  if (axios.isAxiosError(error)) {
    const apiError = error.response?.data;
    if (typeof apiError === "string" && (apiError as string).length > 0) {
      return toast.error(apiError);
    }
    return toast.error(apiError?.message || apiError?.error || error.message);
  }
  if (error instanceof Error) {
    return toast.error(error.message);
  }
  if (
    error &&
    typeof error === "object" &&
    "message" in error &&
    typeof error.message === "string"
  ) {
    return toast.error(error.message);
  }
  return toast.error("Something went wrong!");
}

export const asyncWrapper = (fn) => (payload, api) => {
  return Promise.resolve(fn(payload, api)).catch((error) => {
    return api.rejectWithValue(handleApiError(error));
  });
};
