import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { ReactComponent as DeleteIcon } from "../../../assests/icons/bin.svg";
import { ReactComponent as DescriptionIcon } from "../../../assests/icons/add-page.svg";
import { ReactComponent as EditIcon } from "../../../assests/icons/edit-icon.svg";
import { ReactComponent as LaunchIcon } from "../../../assests/icons/view.svg";
import { Link } from "react-router-dom";
import { SweetAlert } from "../../../utils/sweetAlert/Index";
import { useDispatch, useSelector } from "react-redux";
import { deletePackageAsyncThunk } from "../../../redux/slices/package/packageSlice";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#EBE5FF",
    color: "#454545",
    fontWeight: 500,
    textAlign: "center",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  "td, th": {
    border: 0,
    minWidth: "150px",
    textAlign: "center",
  },
}));

export default function Index({ tests }) {
  const loading = useSelector(
    (store) => store.packages.deletePackageAsyncThunk
  );
  const subjects = useSelector((store) => store.dropdown.subjects);
  const packages = useSelector((store) => store.packages.packages);
  const states = useSelector((store) => store.dropdown.stateDropdown);

  const dispatch = useDispatch();
  const handlePackageDelete = (id) => {
    dispatch(deletePackageAsyncThunk(id));
    // SweetAlert("success", "Sent", "Assessment Link Sent Successfully", "Ok");
  };
  return (
    <>
      <p className="text-[#202020] font-poppins leading-4 text-[10px] text-right mt-5">
        Count: {tests?.total}
      </p>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Sort</StyledTableCell>
              <StyledTableCell>Test Name</StyledTableCell>
              <StyledTableCell>Subject</StyledTableCell>
              <StyledTableCell>Package Name</StyledTableCell>
              <StyledTableCell>Test Conducted By</StyledTableCell>
              <StyledTableCell>Total Questions</StyledTableCell>
              <StyledTableCell>Duration</StyledTableCell>
              <StyledTableCell>State</StyledTableCell>
              <StyledTableCell>Package Published</StyledTableCell>
              <StyledTableCell align="right">Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tests?.data?.map((test, ind) => {
              const selectedSubjects = subjects.filter((subject) =>
                test.subjects_in_test.includes(subject.id)
              );
              const packageName = packages?.data?.find(pack => test?.package_id === pack.package_id)?.package_name || "N/A";
              const stateShortName = states.find(state => state.id === test?.test_conducted_by)?.short_name || "N/A";

              return (
                <StyledTableRow key={ind}>
                  <StyledTableCell>{ind + 1}</StyledTableCell>
                  <StyledTableCell>{test?.test_name}</StyledTableCell>
                  <StyledTableCell>
                    {selectedSubjects
                      .map((subject) => subject.subject_name)
                      .join(", ")}
                  </StyledTableCell>
                  <StyledTableCell>{packageName}</StyledTableCell>
                  <StyledTableCell>{stateShortName}</StyledTableCell>
                  <StyledTableCell>
                    {test.max_number_of_questions}
                  </StyledTableCell>
                  <StyledTableCell>{test?.test_duration}</StyledTableCell>
                  <StyledTableCell>{test.test_for_state}</StyledTableCell>
                  <StyledTableCell>
                    {test?.is_active ? (
                      <span className="px-3 py-1 rounded-full bg-[#52C479] text-white">
                        Yes
                      </span>
                    ) : (
                      <span className="px-3 py-1 rounded-full bg-red-600 text-white">
                        No
                      </span>
                    )}
                  </StyledTableCell>
                  <StyledTableCell>
                    <div className="flex items-center justify-center gap-3">
                    <Link to={`/company/test/edit/${test?.test_id}`}>
                      <EditIcon />
                    </Link>
                    <DeleteIcon
                      // onClick={() => handlePackageDelete(test?.package_id)}
                      className="cursor-pointer"
                      />
                      </div>
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
