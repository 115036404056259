import { ApiUrls } from "./apiUrls.ts";
import AxiosInstance from "./axiosIntance.ts";

export const ApiRequest = {

  // auth
  login: async (data: any) =>
    await AxiosInstance.post(`${ApiUrls.login}`, data),
  forgotPassword: async (data: any) =>
    await AxiosInstance.post(`${ApiUrls.forgotPassword}`, data),
  changePassword: async (data: any, id: any) => await AxiosInstance.put(`${ApiUrls.confirmPassword}/${id}`, data),
  allUsers: async (data: any) => (await AxiosInstance.get(`${ApiUrls.search}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  })
),
  createUser: async (data: any) =>
    await AxiosInstance.post(`${ApiUrls.createUser}`, data),
  verificationUser: async (id: any, data: object) =>
    await AxiosInstance.put(`${ApiUrls.verificationUser}/${id}`, data),
  // packages
  getPackages: async (data) => {
    const queryParams = new URLSearchParams(data).toString();
    return (await AxiosInstance.get(`${ApiUrls.getPackages}?${queryParams}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
  )
  },
  getPackage: async (data: string) =>
    (await AxiosInstance.get(`${ApiUrls.getPackage}/${data}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    })
  ),
  getPublishPackage: async (data: string) =>
    await AxiosInstance.get(`${ApiUrls.getPublishPackage}/${data}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),
  createPackage: async (data: any) =>
    await AxiosInstance.post(ApiUrls.createPackage, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),
  editPackage: async ({ data, id }) =>
    await AxiosInstance.patch(`${ApiUrls.editPackage}/${id}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  deletePackage: async (id: string) =>
    await AxiosInstance.delete(`${ApiUrls.deletePackage}/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),
  searchPackage: async (data: any) =>
    await AxiosInstance.post(`${ApiUrls.searchPackage}`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),
  publishPackage: async ({ id, data }) =>
    await AxiosInstance.patch(`${ApiUrls.publishPackage}/${id}`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),
  // essays
  getEssays: async (param: string) =>
    await AxiosInstance.get(`${ApiUrls.getEssays}/${param}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),
  createEssay: async (data: any) =>
    await AxiosInstance.post(ApiUrls.createEssay, data,{
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),
  editEssay: async ({ data, id }) =>
    await AxiosInstance.patch(`${ApiUrls.editEssay}/${id}`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),
  deleteEssay: async (id: string) =>
    await AxiosInstance.delete(`${ApiUrls.deletePackage}/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),
  // get dropdowns
  getStateDropdown: async () =>
    await AxiosInstance.get(`${ApiUrls.getStateDrown}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),
  getExamTypesDropdown: async () =>
    await AxiosInstance.get(`${ApiUrls.getExamTypesDropdown}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),
  getExamConductedByDropdown: async () =>
    await AxiosInstance.get(`${ApiUrls.getExamConductedByDropdown}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),
  getSubjectsDropdown: async () =>
    await AxiosInstance.get(`${ApiUrls.getSubjectsDropdown}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),

  getSearchedPakageDropdown: async (data: any) =>
    await AxiosInstance.post(ApiUrls.getSearchedPakageDropdown, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),
  

  getTopicsDropdown: async () =>
    await AxiosInstance.get(`${ApiUrls.getTopicsDropdown}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),
  getSubtopicsDropdown: async () =>
    await AxiosInstance.get(`${ApiUrls.getSubtopicsDropdown}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),

  // questions
  addQuestion: async (data: any) =>
    await AxiosInstance.post(ApiUrls.addQuestion, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),
  addComprehension: async (data: any) =>
    await AxiosInstance.post(ApiUrls.addComprehension, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),
  addBulkQuestions: async (data: any) =>
    await AxiosInstance.post(ApiUrls.addBulkQuestions, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),
  searchQuestions: async (data: any) =>
    await AxiosInstance.post(ApiUrls.searchQuestions, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),

  //test
createTest: async (data: any) =>
  await AxiosInstance.post(ApiUrls.createTest, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  }),
getTests: async (data) => {
  const queryParams = new URLSearchParams(data).toString();
  return await AxiosInstance.get(`${ApiUrls.getTests}?${queryParams}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
},
searchTests: async (data: any) =>
  await AxiosInstance.post(`${ApiUrls.searchTest}`, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  }),
getTest: async (data) => {
  return await AxiosInstance.get(`${ApiUrls.getTest}?id=${data}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
},
editTest: async ({ data, id }) =>
  await AxiosInstance.patch(`${ApiUrls.editTest}?id=${id}`, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  }),
  getQuestion: async (id) =>
    await AxiosInstance.get(`${ApiUrls.addQuestion}/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),
  editQuestion: async ({ data, id }) =>
    await AxiosInstance.patch(`${ApiUrls.addQuestion}/${id}`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),
  getComprehension: async (id) =>
    await AxiosInstance.get(`${ApiUrls.addComprehension}/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),
  editComprehension: async ({ data, id }) =>
    await AxiosInstance.put(`${ApiUrls.editComprehension}/${id}`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),
  deleteQuestion: async (id: string) =>
    await AxiosInstance.delete(`${ApiUrls.addQuestion}/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }),
};

