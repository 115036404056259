import { toast } from "react-toastify";
import { ApiRequest } from "../../../utils/apiHelpers/apiRequest.ts";
import { asyncWrapper } from "../../../utils/apiHelpers/handleApiError.ts";
import { handleLoadings } from "../../../utils/apiHelpers/handleLoading.ts";
import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";

export const loginAsyncThunk = createAsyncThunk(
  "auth/loginAsyncThunk",
  asyncWrapper(async ({ data, navigate }, thunk) => {
    const response = await ApiRequest.login(data);
    if (response.status == 201) {
      console.log("response----->", response);
      localStorage.setItem("app-token", JSON.stringify(response.data.user));
  localStorage.setItem('role', response.data.user.role);
      localStorage.setItem("token", response.data.token);
      toast.success("Login successfully!");
      navigate("/company/dashboard");
      window.location.reload();
    }
    return response.data;
  })
);
export const verificationEmailAsyncThunk = createAsyncThunk(
  "auth/verificationEmailAsyncThunk",
  asyncWrapper(async ({ data, action, setOpen }, thunk) => {
    console.log("data----->", data);
    let dataa = {activationCode: data.otp}
    let user = JSON.parse(localStorage.getItem('app-token'));
    console.log("local user", user);
    
    const response = await ApiRequest.verificationUser(user.id,dataa);
    console.log("response---->", response);
    if (response.status == 200) {
      // thunk.dispatch(getUsersAsyncThunk());
      toast.success("Account verified");
      // action.resetForm();
      // setOpen(false);
    }
    return response.data;
  })
);
export const createUserAsyncThunk = createAsyncThunk(
  "auth/createUserAsyncThunk",
  asyncWrapper(async ({ data, action, setOpen }, thunk) => {
    const response = await ApiRequest.createUser(data);
    if (response.status == 201) {
      // thunk.dispatch(getUsersAsyncThunk());
      toast.success("Signup successfully!");
      console.log("response", response);  
      localStorage.setItem('app-token', JSON.stringify(response.data));
      // localStorage.setItem('token', response.);
      // action.resetForm();
      // setOpen(false);
    }
    return response.data;
  })
);
export const getUsersAsyncThunk = createAsyncThunk(
  "auth/getUsersAsyncThunk",
  asyncWrapper(async (data, thunk) => {
    const response = await ApiRequest.allUsers();
    return response.data;
  })
);
export const getPackageAsyncThunk = createAsyncThunk(
  "auth/getPackageAsyncThunk",
  asyncWrapper(async (data, thunk) => {
    const response = await ApiRequest.getPackage(data);
    return response.data;
  })
);

export const searchPackageAsyncThunk = createAsyncThunk(
  "auth/searchPackageAsyncThunk",
  asyncWrapper(async (data, thunk) => {
    const response = await ApiRequest.searchPackage(data);
    if (response.status == 200) {
      toast.success("Package delete successfully!");
    }
    return response.data;
  })
);
export const deletePackageAsyncThunk = createAsyncThunk(
  "auth/deletePackageAsyncThunk",
  asyncWrapper(async (id, thunk) => {
    const response = await ApiRequest.deletePackage(id);
    if (response.status == 200) {
      toast.success("Package delete successfully!");
    }
    return response.data;
  })
);

export const createEssayAsyncThunk = createAsyncThunk(
  "auth/createEssayAsyncThunk",
  asyncWrapper(async ({ data, pckg }, thunk) => {
    const response = await ApiRequest.createEssay(data);
    if (response.status == 201) {
      toast.success("Essay added successfully!");
      thunk.dispatch(getEssaysAsyncThunk(pckg));
    }
    return response.data;
  })
);

export const editEssayAsyncThunk = createAsyncThunk(
  "auth/editEssayAsyncThunk",
  asyncWrapper(async ({ data, id, pckg, fun }, thunk) => {
    const response = await ApiRequest.editEssay({ data, id });
    if (response.status == 200) {
      toast.success("Essay updated successfully!");
      thunk.dispatch(getEssaysAsyncThunk(pckg));
      fun();
    }
    return response.data;
  })
);

export const getEssaysAsyncThunk = createAsyncThunk(
  "auth/getEssaysAsyncThunk",
  asyncWrapper(async (data, thunk) => {
    const response = await ApiRequest.getEssays(data);
    return response.data;
  })
);

export const addQuestionAsyncThunk = createAsyncThunk(
  "auth/addQuestionAsyncThunk",
  asyncWrapper(async ({ data, action, navigate }, thunk) => {
    const response = await ApiRequest.addQuestion(data);
    if (response.status == 201) {
      toast.success("Question added successfully!");
      action.resetForm();
      // navigate("/company/packages");
    }
    return response.data;
  })
);

const initialState = {
  packages: [],
  package: {},
  essays: [],
  user: null,
  token: null,
  users: [],
  loadings: {
    loginAsyncThunk: false,
    getPackageAsyncThunk: false,
    deletePackageAsyncThunk: false,
    searchPackageAsyncThunk: false,
    editEssayAsyncThunk: false,
    addQuestionAsyncThunk: false,
    getUsersAsyncThunk: false,
    createUserAsyncThunk: false,
  },
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  extraReducers: (builder) => {
    builder
      // auth
      .addCase(loginAsyncThunk.fulfilled, (state, action) => {
        state.user = action.payload;
      })
      .addCase(getUsersAsyncThunk.fulfilled, (state, action) => {
        state.users = action.payload;
      })
      .addCase(getPackageAsyncThunk.fulfilled, (state, action) => {
        state.package = action.payload;
      })
      .addCase(searchPackageAsyncThunk.fulfilled, (state, action) => {
        state.packages = action.payload;
      })
      // .addCase(deletePackageAsyncThunk.fulfilled, (state, action) => {
      //   state.packages = action.payload;
      // })
      // essays
      .addCase(getEssaysAsyncThunk.fulfilled, (state, action) => {
        state.essays = action.payload;
      })
      .addMatcher(
        isAnyOf(
          loginAsyncThunk,
          getEssaysAsyncThunk,
          deletePackageAsyncThunk,
          searchPackageAsyncThunk,
          editEssayAsyncThunk,
          addQuestionAsyncThunk,
          getUsersAsyncThunk
        ),
        handleLoadings
      );
  },
  reducers: {
    setAuthenticatedUser: (state, action) => {
      state.user = action.payload;
    },
    setAuthToken: (state, action) => {
      state.token = action.payload;
    }
  },
});

export default authSlice.reducer;
export const { setAuthenticatedUser, setAuthToken } = authSlice.actions;
