import OtpInput from "../components/OtpInput";

const OtpVerificationComponent = ({styles, handleOtpSubmit}) => {
  return (
    <>
      <div className="flex items-center justify-center md:py-12 full-screen">
        <div className="md:w-full md:max-w-[600px]">
          <h1 className=" my-0 text-center font-Inter text-3xl font-bold text-black md:text-4xl lg:text-center">
            {"Otp Verification"}
          </h1>
          <OtpInput length={4} onOtpSubmit={handleOtpSubmit} buttonStyle={styles.buttonStyle} />
        </div>
      </div>
    </>
  );
};

export default OtpVerificationComponent;
