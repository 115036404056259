import React from "react";
import { createBrowserRouter, Navigate } from "react-router-dom";
import Signin from "./pages/signin";
import Dashboard from "./pages/dashboard";
import Users from "./pages/users";
import Packages from "./pages/packages";
import Tests from "./pages/tests";
import EditPackage from "./pages/edit-package";
import EditTest from "./pages/edit-test";
import CreateTest from "./pages/create-test";
import Profile from "./pages/profile";
import QuestionBank from "./pages/question-bank";
import AddQuestion from "./pages/add-question";
import AddExcelQuestion from "./pages/add-excel-question";
import AddWordQuestion from "./pages/add-word-question";
import CreateEssay from "./pages/create-essay";
import AdminTasks from "./pages/admin-tasks";
import PublishPackage from "./pages/publish-package";
import PublishNewPackage from "./pages/publish-new-package";
import ProtectedRoute from "./components/ProtectedRoutes/Index";
import Package from "./pages/create-package";
import EditQuestion from "./pages/edit-question";
import SignUpPages from "./pages/signup";
import PrivateRoute from "./PrivateRoute";
import DashboardOne from "./pages/dashboard1";
import NotFound from "./pages/NotFound";
import ForgotPasswordPage from "./pages/forgot-password";
import ConfirmPasswordPage from "./pages/confirm-password";

const router = createBrowserRouter([
  { path: "/", element: <Navigate to="/company/dashboard" /> },
  { path: "/signin", element: <Signin /> },
  {
    element: <ProtectedRoute />,
    children: [
      {
          path: "/company/dashboard",
          element:  <PrivateRoute component={<DashboardOne />} />,
        },
      // { path: "/company/dashboard", element: <PrivateRoute component={<Dashboard />} /> },
      
      // {
      //   path: "/company/test/edit/:id",
      //   element:  <PrivateRoute component={<EditTest />} />,
      // },
      // { path: "/company/test/create", element: <PrivateRoute component={<CreateTest />} /> },
      // { path: "/company/profile", element: <PrivateRoute component={<Profile />} /> },
      // { path: "/company/question-bank", element: <PrivateRoute component={<QuestionBank />} /> },
      // { path: "/company/question/add", element: <PrivateRoute component={<AddQuestion />} /> },
      // {
      //   path: "/company/question/edit/:id",
      //   element: <PrivateRoute component={<EditQuestion />} />,
      // },
      // {
      //   path: "/company/question/add-excel-question",
      //   element: <PrivateRoute component={<AddExcelQuestion />} />,
      // },
      // {
      //   path: "/company/question/add-word-question",
      //   element: <PrivateRoute component={<AddWordQuestion />} />,
      // },
      // { path: "/company/essays/add", element: <PrivateRoute component={<CreateEssay />} /> },
      // { path: "/company/admin-tasks", element: <PrivateRoute component={<AdminTasks />} /> },
      // {
      //   path: "/company/publish-package",
      //   element: <PrivateRoute component={<PublishPackage />} />,
      // },
      // {
      //   path: "/company/package/publish-package/:id",
      //   element: <PrivateRoute component={<PublishNewPackage />} />,
      // },
      {
        path: "/sign-up",
        element: <SignUpPages />,
      },
      {
        path: "/login",
        element: <Signin />,
      },
      {
        path: "forgot-password",
        element: <ForgotPasswordPage />
      },
      {
        path: "/confirm-password/:id",
        element: <ConfirmPasswordPage />
      }
    ],
  },
  {
    path: "*",  
    element: <NotFound />,
  },
]);

export default router;
