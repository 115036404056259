import React from "react";
import { useFormik } from "formik";
import { forgotPasswordSchema } from "../utils/yup/authSchemas.ts";
import { useNavigate } from "react-router-dom";
import { Button, InputLabel, TextField } from "@mui/material";
import { ApiRequest } from "../utils/apiHelpers/apiRequest.ts";
import { toast } from "react-toastify";
import OtpVerificationComponent from "./otpVerification.jsx";
import { useDispatch } from "react-redux";
import { verificationEmailAsyncThunk } from "../redux/slices/auth/authSlice.js";

const ForgotPasswordPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isActive, setIsActive] = React.useState(false);
    const [idd, setIdd] = React.useState('');

    const initialValues = {
        email: "",
        // password: "",
    };

    // Define onSubmit function before useFormik
    const onSubmit = async (valuess) => {
        try {
            const response = await ApiRequest.forgotPassword(valuess);
            if(response.data) {
                setIdd(response.data.id);
                setIsActive(!isActive);
            }
            console.log("response", response)
        }catch (e) {
            toast.error(e.response.data.message)
            console.log("Error", e);
        }
    }

    const { handleBlur, handleChange, handleSubmit, values, errors, touched } = useFormik({
        initialValues,
        validationSchema: forgotPasswordSchema,
        onSubmit, // Now `onSubmit` is correctly defined
    });


    const styles = {
        inputWidth: {
          width: "18rem",
        },
        buttonMainDiv: {
          display: "flex",
          width: "100%",
        },
        createAccount: {
          width: "50%",
          fontWeight: 700,
          fontSize: "13px",
        },
    
        buttonStyle: {
          width: "50%",
          fontWeight: 700,
          fontSize: "13px",
          marginTop: "20px",
        },
    
        loginWithGoogle: {
          marginLeft: "27px",
          width: "19rem",
          background: "black",
          fontWeight: 700,
          fontSize: "13px",
        },
      };

      const handleOtpSubmit = (otp) => {
        // alert(`OTP Submitted: ${otp}`);
        const setOpenn = true;
        const action = true;
    
        dispatch(verificationEmailAsyncThunk({ data: { otp }, action, setOpenn }));
        navigate(`/confirm-password/${idd}`);
      };

    return (
        <>
        {!isActive && <div className="flex items-center justify-center md:py-12 full-screen">
            <div className="md:w-full md:max-w-[600px]">
                <h1 className=" my-0 text-center font-Inter text-3xl font-bold text-black md:text-4xl lg:text-center">
                    {"Forgot Password"}
                </h1>
                <form className="mt-4 space-y-5 md:mt-14" onSubmit={handleSubmit}>
                    <div>
                        <InputLabel className="mb-2 font-poppins text-[#202020]">
                            {"Email Id:"}
                        </InputLabel>
                        <TextField
                            fullWidth
                            data-testid="email"
                            type="text"
                            variant="outlined"
                            color="secondary"
                            placeholder="johndoe@gmail.com"
                            borderRadius="20px"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="email"
                            error={touched.email && Boolean(errors.email)}
                            helperText={touched.email && errors.email}
                        />
                    </div>

                    <Button
                        fullWidth
                        variant="contained"
                        data-testid="submit-btn"
                        color="primary"
                        type="submit"
                        name="/login"
                        className="p-2.5 capitalize leading-6 text-base font-bold font-poppins"
                    >
                        Submit
                    </Button>
                </form>
            </div>
        </div>}
        {isActive && (
        <>
          <OtpVerificationComponent
            handleOtpSubmit={handleOtpSubmit}
            styles={styles}
          />
        </>
      )}
        </>
    );
};

export default ForgotPasswordPage;
