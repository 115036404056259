import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { ReactComponent as DeleteIcon } from "../../assests/icons/bin.svg";
import { ReactComponent as EditIcon } from "../../assests/icons/edit.svg";
import EditEssayModal from "../EditEssay/EditEssay";
import { useDispatch } from "react-redux";
import { deleteEssayAsyncThunk } from "../../redux/slices/package/packageSlice";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "white",
    color: "#454545",
    fontWeight: 500,
    textAlign: "left",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  "td, th": {
    minWidth: "150px",
    textAlign: "left",
  },
}));

export default function Index({ essays }) {
  const [open, setOpen] = React.useState(false);
  const [essayData, setEssayData] = React.useState({
    id: null,
    essay_name: "",
    essay_type: "",
    duration: 0,
  });

  const dispatch = useDispatch();

  const handleEditPackage = (essay) => {
    setEssayData({
      id: essay?.essay_id,
      essay_name: essay?.essay_name,
      essay_type: essay?.essay_type,
      duration: essay?.duration,
    });
    setOpen(true);
  };
  const handleDeleteEssay = (id) => {
    dispatch(deleteEssayAsyncThunk(id));
  };
  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Essay Type</StyledTableCell>
              <StyledTableCell>Essay Name</StyledTableCell>
              <StyledTableCell>Duration</StyledTableCell>
              <StyledTableCell>Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {essays?.map((essay, ind) => (
              <StyledTableRow key={ind}>
                <StyledTableCell>{essay.essay_name}</StyledTableCell>
                <StyledTableCell>{essay.essay_type}</StyledTableCell>
                <StyledTableCell>{essay.duration}</StyledTableCell>
                <StyledTableCell
                  onClick={(e) => e.stopPropagation()}
                  className="flex items-center gap-3"
                >
                  <EditIcon
                    className="cursor-pointer"
                    onClick={() => handleEditPackage(essay)}
                  />
                  <DeleteIcon
                   className="cursor-pointer"
                    onClick={() => handleDeleteEssay(essay?.essay_id)}
                  />
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <EditEssayModal open={open} setOpen={setOpen} essayData={essayData} />
    </>
  );
}
