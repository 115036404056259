// import { Editor } from "@tinymce/tinymce-react";
// import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
// import { memo, useEffect, useState } from "react";

// const DynamicEditor = ({
//   id,
//   setFieldValue,
//   value,
//   onBlur,
//   height,
//   questionType,
// }) => {
//   const [content, setContent] = useState(value);

//   useEffect(()=>{
//     setContent(value)
//   },[value])
//   const handleChange = (e) => {
//     if (questionType == "TrueFalse") return;
//     setContent(e);
//   };
//   return (
//     <Editor
//       id={id}
//       value={content}
//       apiKey={process.env.REACT_APP_TINY_KEY}
//       onEditorChange={(e) => handleChange(e)}
//       onBlur={() => setFieldValue(id, content)}
//       init={{
//         height: height || 300,
//         statusbar: false,
//         menubar: false,
//         plugins:
//           "image imageupload preview importcss searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen image link table anchor insertdatetime advlist lists wordcount codesample charmap",
//         toolbar:
//           "bold italic underline strikethrough blocks forecolor superscript subscript | image outdent indent  numlist bullist | table link codesample charmap | ltr rtl",
//         toolbar_sticky: true,
//         // toolbar_sticky_offset: isSmallScreen ? 102 : 108,
//         images_upload_handler: async (blobInfo) => {
//           try {
//             // 1. Create S3 client
//             const s3 = new S3Client({
//               region: process.env.REACT_APP_AWS_REGION, // e.g., 'us-west-2'
//               credentials: {
//                 accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
//                 secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
//               },
//             });
//             // 2. Generate unique file name
//             const fileName = `uploads/${Date.now()}-${blobInfo.filename()}`;

//             // 3. Set S3 upload parameters
//             const uploadParams = {
//               Bucket: process.env.REACT_APP_AWS_BUCKET, // Your bucket name
//               Key: fileName,
//               Body: blobInfo.blob(), // Image blob from TinyMCE
//               ACL: "public-read", // Make the file public
//               ContentType: blobInfo.blob().type,
//             };

//             // 4. Upload image to S3
//             await s3.send(new PutObjectCommand(uploadParams));

//             // 5. Image URL
//             const imageUrl = `https://${uploadParams.Bucket}.s3.amazonaws.com/${fileName}`;

//             // 6. Return the image URL to TinyMCE
//             return imageUrl;
//           } catch (error) {
//             console.error("Image upload failed:", error);
//           }
//         },
//       }}
//     />
//   );
// };

// export default memo(DynamicEditor);

import { useEffect, useRef } from "react";
import AWS from "aws-sdk";

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION,
});

const s3 = new AWS.S3({
  params: { Bucket: process.env.REACT_APP_AWS_BUCKET },
});
const DynamicEditor = ({
  id,
  value,
  setFieldValue,
  onBlur,
  questionType,
  error,
}) => {
  const refdiv = useRef(null);
  const rteRef = useRef(null); // Store rte instance

  window.rte_file_upload_handler = async function (
    file,
    callback,
    optionalIndex,
    optionalFiles
  ) {
    const url = await handleImageUpload(file);
    callback(url); 
  };

  useEffect(() => {
    if (refdiv.current && !rteRef.current) {
      rteRef.current = new window.RichTextEditor(refdiv.current);
      rteRef.current.setHTMLCode(value); // Initialize with value

      rteRef.current.attachEvent("exec_command_insertfile", (state, cmd, value) => {
        state.returnValue = true;
        console.log("File upload triggered");
      });

      rteRef.current.attachEvent("change", () => {
        const htmlContent = rteRef.current.getHTMLCode();
        if (questionType === "TrueFalse") {
          setFieldValue(id, value);
        } else {
          setFieldValue(id, htmlContent);
        }
      });
    }
  }, []);

  // Reset editor when value is updated
  useEffect(() => {
    if (rteRef.current && value === "") {
      rteRef.current.setHTMLCode(""); // Reset editor when value is empty
    }
  }, [value]);

  const handleImageUpload = async (file) => {
    const fileName = `${Date.now()}_${file.name}`;

    const params = {
      Bucket: process.env.REACT_APP_AWS_BUCKET,
      Key: fileName,
      Body: file,
      ContentType: file.type,
      ACL: "public-read",
    };

    try {
      const upload = await s3.upload(params).promise();
      return upload.Location;
    } catch (err) {
      console.error("Error uploading file: ", err);
      return null;
    }
  };

  return <div ref={refdiv} />;
};

export default DynamicEditor;
