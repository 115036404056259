import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { ReactComponent as DeleteIcon } from "../../assests/icons/bin.svg";
import { ReactComponent as DescriptionIcon } from "../../assests/icons/add-page.svg";
import { ReactComponent as EditIcon } from "../../assests/icons/edit-icon.svg";
import { ReactComponent as LaunchIcon } from "../../assests/icons/view.svg";
import { Link } from "react-router-dom";
import { SweetAlert } from "../../utils/sweetAlert/Index";
import { useDispatch, useSelector } from "react-redux";
import { deletePackageAsyncThunk } from "../../redux/slices/package/packageSlice";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#EBE5FF",
    color: "#454545",
    fontWeight: 500,
    textAlign: "center",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  "td, th": {
    border: 0,
    minWidth: "150px",
    textAlign: "center",
  },
}));

export default function Index({ packages }) {
  const loading = useSelector(
    (store) => store.packages.deletePackageAsyncThunk
  );

  const dispatch = useDispatch();

  const handlePackageDelete = (id) => {
    dispatch(deletePackageAsyncThunk(id));
    // SweetAlert("success", "Sent", "Assessment Link Sent Successfully", "Ok");
  };
  return (
    <>
      <p className="text-[#202020] font-poppins leading-4 text-[10px] text-right mt-5">
        Count: {packages?.total}
      </p>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Sort</StyledTableCell>
              <StyledTableCell>Package Name</StyledTableCell>
              <StyledTableCell>Exam type</StyledTableCell>
              <StyledTableCell>Test Conducted By</StyledTableCell>
              <StyledTableCell>State</StyledTableCell>
              <StyledTableCell>Grade</StyledTableCell>
              <StyledTableCell>Number of Tests</StyledTableCell>
              <StyledTableCell>Single or Multiple Subject?</StyledTableCell>
              <StyledTableCell>Quality Checked</StyledTableCell>
              <StyledTableCell>Is Published</StyledTableCell>
              <StyledTableCell>Package Price </StyledTableCell>
              <StyledTableCell align="right">Actions</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {packages?.data?.map((pckg, ind) => (
              <StyledTableRow key={ind}>
                <StyledTableCell>{ind + 1}</StyledTableCell>
                <StyledTableCell>{pckg.package_name}</StyledTableCell>
                <StyledTableCell>{pckg?.exam?.exam_name}</StyledTableCell>
                <StyledTableCell>{pckg?.conductedBy?.name}</StyledTableCell>
                <StyledTableCell>
                  {pckg?.state_name?.state_name}
                </StyledTableCell>
                <StyledTableCell>{pckg.grade}</StyledTableCell>
                <StyledTableCell>{pckg.number_of_tests}</StyledTableCell>
                <StyledTableCell>{pckg?.test_type}</StyledTableCell>
                <StyledTableCell>
                  {pckg?.package_quality_checked ? (
                    <span className="px-3 py-1 rounded-full bg-[#52C479] text-white">
                      Yes
                    </span>
                  ) : (
                    <span className="px-3 py-1 rounded-full bg-red-600 text-white">
                      No
                    </span>
                  )}
                </StyledTableCell>
                <StyledTableCell>
                  {pckg?.is_published == "true" ? (
                    <span className="px-3 py-1 rounded-full bg-[#52C479] text-white">
                      Yes
                    </span>
                  ) : (
                    <span className="px-3 py-1 rounded-full bg-red-600 text-white">
                      No
                    </span>
                  )}
                </StyledTableCell>
                <StyledTableCell>{pckg.price}</StyledTableCell>
                <StyledTableCell className="flex items-center gap-3">
                  <Link
                    to={`/company/package/publish-package/${pckg?.package_id}`}
                  >
                    <span className="w-[14px] h-[15px] rounded-[3px] text-[#454545] text-xs bg-[#EBE5FF]">
                      P
                    </span>
                  </Link>
                  <div className="flex items-center gap-1">
                    <span className="w-[14px] h-[15px] rounded-[3px] text-[#454545] text-xs bg-[#EBE5FF]">
                      T
                    </span>
                    <LaunchIcon />
                  </div>

                  <Link to={`/company/package/edit/${pckg?.package_id}`}>
                    <EditIcon />
                  </Link>
                  <Link to={`/company/essays/add?package=${pckg?.package_id}`}>
                    <DescriptionIcon />
                  </Link>

                  <DeleteIcon
                    onClick={() => handlePackageDelete(pckg?.package_id)}
                    className="cursor-pointer"
                  />
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
