import { combineReducers } from "@reduxjs/toolkit";
import packageSlice from "./slices/package/packageSlice";
import dropdownSlice from "./slices/dropdowns/dropdownSlice";
import authSlice from "./slices/auth/authSlice";
import  testSlice from "./slices/test/testSlice";

import questionSlice from "./slices/question/questionSlice";

export const rootReducer = combineReducers({
  packages: packageSlice,
  dropdown: dropdownSlice,
  auth: authSlice,
  tests: testSlice,
  question: questionSlice

});
export type RootState = ReturnType<typeof rootReducer>;
