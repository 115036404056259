import { useFormik } from "formik";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { CircularProgress, InputLabel } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginAsyncThunk } from "../redux/slices/auth/authSlice";
import { loginSchema } from "../utils/yup/authSchemas.ts";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LockOpenIcon from "@mui/icons-material/LockOpen";

export default function Signin() {
  const loading = useSelector((store) => store.auth.loginAsyncThunk);
  const initialValues = {
    email: "",
    password: "",
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onSubmit = async (values) => {
    dispatch(loginAsyncThunk({ data: values, navigate }));
  };

  const { handleBlur, handleChange, handleSubmit, values, errors, touched } =
    useFormik({
      initialValues,
      validationSchema: loginSchema,
      onSubmit,
    });

  return (
    <div className="flex items-center justify-center md:py-12 full-screen">
<div className="md:w-full md:max-w-[600px]">
      <h1 className=" my-0 text-center font-Inter text-3xl font-bold text-black md:text-4xl lg:text-center">
        Login to your account
      </h1>
      <form className="mt-4 space-y-5 md:mt-14" onSubmit={handleSubmit}>
        <div>
          <InputLabel className="mb-2 font-poppins text-[#202020]">
            Email Id:
          </InputLabel>
          <TextField
            fullWidth
            data-testid="email"
            type="text"
            variant="outlined"
            color="secondary"
            placeholder="johndoe@gmail.com"
            borderRadius="20px"
          
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            name="email"
            error={touched.email && Boolean(errors.email)}
            helperText={touched.email && errors.email}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              endAdornment: <MailOutlineIcon />,
            }}
          />
        </div>
        <div>
          <InputLabel className="mb-2 font-poppins text-[#202020]">
            Your Password
          </InputLabel>
          <TextField
            fullWidth
            name="password"
            type="password"
            variant="outlined"
            color="secondary"
             placeholder="Enter your password"
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.password && Boolean(errors.password)}
            id="password"
            helperText={touched.password && errors.password}
            InputProps={{
              endAdornment: <LockOpenIcon />,
            }}
          />
        </div>
        <Link
          to="/forgot-password"
          className="flex justify-end font-poppins text-base font-bold leading-6 text-primary no-underline"
        >
          Forgot Password ?
        </Link>
        <Button
          fullWidth
          variant="contained"
          data-testid="submit-btn"
          color="primary"
          type="submit"
          name="/login"
          disabled={loading}
          className="p-2.5 capitalize leading-6 text-base font-bold font-poppins"
        >
          {loading ? (
            <CircularProgress className="text-secodary" size={24} />
          ) : (
            "Login now"
          )}
        </Button>
        <div className="separator">
  <div className="line"></div>
  <span>OR</span>
  <div className="line"></div>
</div>
<div className="button-group">
  {/* <Link to={"/sign-up"} className="btn-secondary">Create Account</Link>
  <Link to={"/sign-up"} className="btn-google">Sign-in with Google</Link> */}

  <button className="btn-secondary"><Link to={"/sign-up"} >{"Create Account"}</Link></button>
  <button className="btn-google"><Link to={"/sign-up"} >{"Sign-in with Google"}</Link></button>
</div>     <p className="signup-text">
      {"Don’t have an account?"} <Link to={"/sign-up"} >{"Sign Up"}</Link>
    </p>  </form>
    </div>
  </div>

  );
}
