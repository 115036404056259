import * as Yup from "yup";

export const testSchema = Yup.object().shape({
  test_name: Yup.string().required("Test name is required"),
  test_description: Yup.string().required("Test description is required"),
  package_id: Yup.number()
  .required('Test Package Name is required'),
  max_number_of_questions: Yup.number()
    .integer()
    .min(1, "At least 1 question is required")
    .required("Max number of questions is required"),
    subjects_in_test: Yup
    .array()
    .of(Yup.string().required("Subject is required")) // Ensure array elements are strings
    .min(1, "At least one subject is required")
    .required("Subjects are required"),
  test_for_state: Yup.string().required("State is required"),
  test_duration: Yup.string().required("Test duration is required"),
  grade: Yup.number()
    .integer()
    .min(1, "Grade must be at least 1")
    .required("Grade is required"),
  difficulty_level: Yup.string().required("Difficulty level is required"),
  allow_student_timer_control: Yup.boolean().required("Student Allowed to Select Timer is required"),
  show_calculations: Yup.boolean().required("Show Calculation is required"),
  quality_checked: Yup.boolean().required("Quality Checked is Required"),
  is_active: Yup.boolean().required("Is Active is Required"),
  status: Yup.string()
    .required("Status is required"),
  exam_type: Yup.number().required("ExamType is required"),
  test_conducted_by: Yup.number().required("Test Conducted In State By is required"),
  // created_at: Yup.date().required("Creation date is required"),
});
