import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { setAuthToken } from "../redux/slices/auth/authSlice";
import { getItem } from "../utils/basicService";

const PrivateRoute = ({ component }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    // Retrieve token from local storage (or any other storage mechanism)
    let token = getItem('token');
    
    // If token doesn't exist, redirect to login
    if (!token || token === "null") {
      navigate('/login');
    } else {
      // Dispatch token to redux
      dispatch(setAuthToken(token));
    }
  }, [dispatch, navigate]);

  // If the token is available, render the component
  let token = getItem('token'); // This can be from local storage directly or redux state, depending on your flow
  return token && token !== "null" ? component : null;
};

export default PrivateRoute;
