import * as yup from "yup";

export const packageSchema = yup.object().shape({
  package_name: yup.string().required("Name is required"),
  package_description: yup.string().required("Description is required"),
  package_comments_by_admin: yup.string().required("Comment is required"),
  discount_applied: yup.string().required("discount is required"),
  price: yup.string().required("price is required"),
  state_id: yup.string().required("state is required"),
  package_duration: yup.string().required("Package duration is required"),
  exam_type: yup.string().required("Exam type is required"),
  subjects_in_package: yup
    .array()
    .of(yup.string().required("Subject is required")) // Ensure array elements are strings
    .min(1, "At least one subject is required")
    .required("Subjects are required"),
  grade: yup.string().required("grade is required"),
  is_active: yup.string().required("Active is required"),
  package_quality_checked: yup
    .string()
    .required("Package quality checked is required"),
  is_package_free: yup.string().required("free is required"),
  test_type: yup.string().required("Test type is required"),
  number_of_tests: yup.string().required("Number of tests are required"),
  // published: yup.string().required("examType is required"),
  package_assigned_to: yup.string().required("Assigned to is required"),
  conducted_by_id: yup.string().required("Exam conducted by is required"),
});

export const editEssaySchema = yup.object().shape({
  essay_name: yup.string().required("Essay name is required"),
  essay_type: yup.string().required("Essay type is required"),
  duration: yup
    .number()
    .required("Duration is required")
    .min(0, "Duration cannot be smaller than 0"),
});
