import * as yup from "yup";

export const questionSchema = yup.object().shape({
  question_text: yup
    .string()
    .required("Question is required")
    .min(3, "Name must be 3 characters long"),
  // question_type: yup.number().required("Question type is required"),
  subtopic: yup.string().required("Sub topic is required"),
  is_image: yup.string().required("Select one option"),
  subject: yup.string().required("Subject is required"),
  grade: yup.string().required("Grade is required"),
  good_feedback: yup.string().required("Correct answer is required"),
  difficulty_level: yup.string().required("Difficulty level is required"),
  topic: yup.string().required("Topic is required"),
  exam_type_id: yup.string().required("Exam type is required"),
  quality_checked: yup.string().required("Quality check is required"),
  options: yup
    .array()
    .transform((field) => field.slice(0, 4))
    .of(
      yup.object().shape({
        option_text: yup.string().required("Option text is required"),
        is_correct: yup.boolean().required("Option is required"),
        is_image_present: yup.string().required("Option is required"),
      })
    ),
});

export const comprehensionQuestionSchema = yup.object().shape({
  passage_text: yup
    .string()
    .required("Paragraph is required is required")
    .min(3, "Name must be 3 characters long"),
  // question_type: yup.number().required("Question type is required"),
  complexity: yup.string().required("Question complexity is required"),
  exam_type_id: yup.string().required("Exam type is required"),
  is_image: yup.string().required("Select one option"),
  subject_id: yup.string().required("Subject is required"),
  topic: yup.string().required("Topic is required"),
  subtopic: yup.string().required("Sub topic is required"),
  grade: yup.string().required("Grade is required"),
  quality_checked: yup.string().required("Quality check is required"),
  total_questions: yup.number().required("Toatl questions are required"),
  max_questions_per_passage: yup.number(),
  questions: yup
    .array()
    .transform((field) => field.slice(0, 2))
    .of(
      yup.object().shape({
        question_text: yup
          .string()
          .required("Question is required")
          .min(3, "Name must be 3 characters long"),
        // question_type: yup.number().required("Question type is required"),
        subtopic: yup.string().required("Sub topic is required"),
        is_image: yup.string().required("Select one option"),
        subject: yup.string().required("Subject is required"),
        grade: yup.string().required("Grade is required"),
        good_feedback: yup.string().required("Correct answer is required"),
        difficulty_level: yup.string().required("Difficulty level is required"),
        topic: yup.string().required("Topic is required"),
        exam_type_id: yup.string().required("Exam type is required"),
        quality_checked: yup.string().required("Quality check is required"),
        options: yup
          .array()
          .transform((field) => field.slice(0, 4))
          .of(
            yup.object().shape({
              answer_text: yup.string().required("Option text is required"),
              is_correct: yup.boolean().required("Correction is required"),
              is_image_present: yup.string().required("Image is required"),
            })
          ),
      })
    ),
});

export const bulkQuestionSchema = yup.object().shape({
  questions: yup.array().of(
    yup.object().shape({
      question_text: yup
        .string()
        .required("Question is required")
        .min(3, "Name must be 3 characters long"),
      question_type: yup.string().required("Question type is required"),
      subtopic: yup.string(),
      is_image: yup.string().required("Select one option"),
      subject: yup.string().required("Subject is required"),
      grade: yup.string().required("Grade is required"),
      good_feedback: yup.string(),
      difficulty_level: yup.string().required("Difficulty level is required"),
      topic: yup.string(),
      exam_type_id: yup.string().required("Exam type is required"),
      quality_checked: yup.string().required("Quality check is required"),
      options: yup
        .array()
        .transform((field) => field.slice(0, 4))
        .of(
          yup.object().shape({
            option_text: yup.string(),
            is_correct: yup.boolean().required("Correction is required"),
            is_image_present: yup.string().required("Image is required"),
          })
        ),
    })
  ),
});

export const excelStepTwoSchema = yup.object().shape({
  questions: yup.array().of(
    yup.object().shape({
      question_text: yup.string(),
      // question_type: yup.string().required("Question type is required"),
      subtopic: yup.string(),
      is_image: yup.string().required("Select one option"),
      subject: yup.string().required("Subject is required"),
      grade: yup.string().required("Grade is required"),
      good_feedback: yup.string(),
      difficulty_level: yup.string().required("Difficulty level is required"),
      topic: yup.string(),
      exam_type_id: yup.string().required("Exam type is required"),
      quality_checked: yup.string().required("Quality check is required"),
      options: yup.array().of(
        yup.object().shape({
          option_text: yup.string(),
          is_correct: yup.boolean().required("Correction is required"),
          is_image_present: yup.string().required("Image is required"),
        })
      ),
    })
  ),
});

export const wordStepTwoSchema = yup.object().shape({
  passage_text: yup.string().min(3, "Name must be 3 characters long"),
  // question_type: yup.number().required("Question type is required"),
  complexity: yup.string().required("Question complexity is required"),
  exam_type_id: yup.string().required("Exam type is required"),
  is_image: yup.string().required("Select one option"),
  subject_id: yup.string().required("Subject is required"),
  topic: yup.string(),
  subtopic: yup.string(),
  grade: yup.string().required("Grade is required"),
  quality_checked: yup.string().required("Quality check is required"),
  total_questions: yup.number().required("Toatl questions are required"),
  max_questions_per_passage: yup.number(),
  questions: yup.array().of(
    yup.object().shape({
      question_text: yup.string().min(3, "Name must be 3 characters long"),
      // question_type: yup.number().required("Question type is required"),
      subtopic: yup.string(),
      is_image: yup.string().required("Select one option"),
      subject: yup.string(),
      grade: yup.string(),
      good_feedback: yup.string(),
      difficulty_level: yup.string(),
      topic: yup.string(),
      exam_type_id: yup.string(),
      quality_checked: yup.string(),
      options: yup.array().of(
        yup.object().shape({
          answer_text: yup.string(),
          is_correct: yup.boolean().required("Correction is required"),
          is_image_present: yup.string().required("Image is required"),
        })
      ),
    })
  ),
});

export const wordStepThreeSchema = yup.object().shape({
  passage_text: yup
    .string()
    .required("Paragraph is required is required")
    .min(3, "Name must be 3 characters long"),
  // question_type: yup.number().required("Question type is required"),
  complexity: yup.string().required("Question complexity is required"),
  exam_type_id: yup.string().required("Exam type is required"),
  is_image: yup.string().required("Select one option"),
  subject_id: yup.string().required("Subject is required"),
  topic: yup.string().required("Topic is required"),
  subtopic: yup.string().required("Sub topic is required"),
  grade: yup.string().required("Grade is required"),
  quality_checked: yup.string().required("Quality check is required"),
  total_questions: yup.number().required("Toatl questions are required"),
  max_questions_per_passage: yup.number(),
  questions: yup.array().of(
    yup.object().shape({
      question_text: yup
        .string()
        .required("Question is required")
        .min(3, "Name must be 3 characters long"),
      // question_type: yup.number().required("Question type is required"),
      subtopic: yup.string().required("Sub topic is required"),
      is_image: yup.string().required("Select one option"),
      subject: yup.string().required("Subject is required"),
      grade: yup.string().required("Grade is required"),
      good_feedback: yup.string().required("Correct answer is required"),
      difficulty_level: yup.string().required("Difficulty level is required"),
      topic: yup.string().required("Topic is required"),
      exam_type_id: yup.string().required("Exam type is required"),
      quality_checked: yup.string().required("Quality check is required"),
      options: yup
        .array()
        .transform((field) => field.slice(0, 4))
        .of(
          yup.object().shape({
            answer_text: yup.string().required("Option text is required"),
            is_correct: yup.boolean().required("Correction is required"),
            is_image_present: yup.string().required("Image is required"),
          })
        ),
    })
  ),
});
